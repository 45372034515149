const colors = {
  primary: {
    25: '#7E7F9C',
    50: '#4F5277',
    100: '#474972',
    200: '#353760',
    300: '#24264F',
  },
  secondary: {
    25: '#FDE5C5',
    50: '#FACA8B',
    100: '#B68150',
    200: '#6D3629',
    300: '#361B14',
  },
  white: '#FFFFFF',
  offWhite: '#ECF0F1',
  foregroundOffWhite: '#EEF0F3',
  background: '#F8F8FA',
  grey: {
    900: '#141414',
    800: '#1F1F1F',
    700: '#333333',
    600: '#545454',
    500: '#717171',
    400: '#919191',
    300: '#CBCBCB',
    200: '#E2E2E2',
    100: '#EEEEEE',
    50: '#F6F6F6',
  },
  blue: {
    300: '#174291',
    200: '#2D8EFF',
    100: '#D4E2FC',
    50: '#EBEDFA',
  },
  red: {
    300: '#870F00',
    200: '#E11900',
    100: '#FED7D2',
    50: '#FFEFED',
  },
  green: {
    300: '#03582F',
    200: '#06C167',
    100: '#C7E8DA',
    50: '#E6F2ED',
  },
  orange: {
    400: '#A01200',
    300: '#AB6400',
    200: '#FF9500',
    100: '#FFE1B6',
    50: '#FFF5ED',
  },
  yellow: {
    300: '#997328',
    200: '#FFC043',
    100: '#FFF2D9',
    50: '#FFFAF0',
  },
  purple: {
    300: '#1F1248',
    200: '#924CC8',
    100: '#E1D6F1',
    50: '#F9F5FF',
  },
  warning: {
    400: '#FDB022',
  },
};

export default colors;
