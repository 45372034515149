import { FC, SVGProps } from 'react';
import colors from '@/constants/theme/colors';

const ScaleSvgIcon: FC<SVGProps<SVGSVGElement>> = ({
  fill = colors.primary[100],
  width = '1.75rem',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 81 80"
      fill="none"
    >
      <path
        d="M43.8259 6.66602V9.99935H67.1592V16.666H43.8259V63.3327H57.1592V69.9993H23.8259V63.3327H37.1592V16.666H13.8259V9.99935H37.1592V6.66602H43.8259ZM17.1592 21.1427L26.5859 30.5727C28.4505 32.4374 29.7203 34.8132 30.2347 37.3996C30.7492 39.986 30.4851 42.6668 29.4759 45.1031C28.4667 47.5394 26.7577 49.6218 24.5651 51.0868C22.3724 52.5519 19.7946 53.3339 17.1576 53.3339C14.5205 53.3339 11.9427 52.5519 9.75003 51.0868C7.55739 49.6218 5.84842 47.5394 4.83923 45.1031C3.83004 42.6668 3.56596 39.986 4.08037 37.3996C4.59478 34.8132 5.86459 32.4374 7.72922 30.5727L17.1592 21.1427ZM63.8259 21.1427L73.2526 30.5727C75.1172 32.4374 76.387 34.8132 76.9014 37.3996C77.4158 39.986 77.1517 42.6668 76.1426 45.1031C75.1334 47.5394 73.4244 49.6218 71.2318 51.0868C69.0391 52.5519 66.4613 53.3339 63.8242 53.3339C61.1872 53.3339 58.6093 52.5519 56.4167 51.0868C54.224 49.6218 52.5151 47.5394 51.5059 45.1031C50.4967 42.6668 50.2326 39.986 50.747 37.3996C51.2614 34.8132 52.5313 32.4374 54.3959 30.5727L63.8259 21.1427ZM17.1592 30.5727L12.4459 35.286C11.5133 36.2183 10.8782 37.4062 10.6208 38.6994C10.3634 39.9927 10.4953 41.3332 10.9998 42.5515C11.5044 43.7698 12.3588 44.8111 13.4552 45.5438C14.5516 46.2764 15.8406 46.6674 17.1592 46.6674C18.4779 46.6674 19.7669 46.2764 20.8632 45.5438C21.9596 44.8111 22.8141 43.7698 23.3186 42.5515C23.8231 41.3332 23.955 39.9927 23.6976 38.6994C23.4402 37.4062 22.8051 36.2183 21.8726 35.286L17.1592 30.5727ZM63.8259 30.5727L59.1126 35.286C58.18 36.2183 57.5449 37.4062 57.2875 38.6994C57.0301 39.9927 57.162 41.3332 57.6665 42.5515C58.171 43.7698 59.0255 44.8111 60.1219 45.5438C61.2183 46.2764 62.5073 46.6674 63.8259 46.6674C65.1445 46.6674 66.4335 46.2764 67.5299 45.5438C68.6263 44.8111 69.4808 43.7698 69.9853 42.5515C70.4898 41.3332 70.6217 39.9927 70.3643 38.6994C70.1069 37.4062 69.4718 36.2183 68.5392 35.286L63.8259 30.5727Z"
        fill={fill}
      />
    </svg>
  );
};

export default ScaleSvgIcon;
